import { useEffect, useRef } from 'react'
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import './ServicePageSpecialist.scss'
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


function ServicePageSpecialist({ content }) {
   const listRef = useRef()

   useEffect(() => {
      const listItems = gsap.utils.toArray(listRef.current.children)
      gsap.set(listItems, { opacity: 0, y: 30, })
      listItems.forEach((item, index) => {
         let animation = gsap.timeline()
            .to(item, { y: 0, opacity: 1, duration: .4, })
         ScrollTrigger.create({
            trigger: item,
            start: "top bottom-=10px",
            animation: animation,
            scrub: false,
         })
      })
   }, []);
   return (
      <section className='service_page_spec'>
         <div className="container">
            <div className="page_spec_wrap">
               <div className="head row">
                  <div className="col-xl-6 col-md-5">
                     <h2 className='head__title'>
                        The specialist <br />
                        who performs <br />
                        the procedure <br />
                     </h2>
                  </div>
                  <div className="col-xl-6 col-md-7">
                     <div className="head_right row">
                        <div className="head_right__text">
                           <p>
                              A trained and certified practitioner at Reforme will conduct the procedure.
                           </p>
                        </div>
                        {/* <div className="head_right__specialist">
                           <div className="avatar">
                              <img src="./img/Services/specialists/spec1.jpg" alt="" />
                           </div>
                           <div className="meta">
                              <div className="name">Melanie Smith</div>
                              <div className="job">Cosmetologist</div>
                           </div>
                        </div> */}
                     </div>
                  </div>
               </div>
               <div className="body_content row">
                  <div className="col-xl-4">
                     <div className="body_content__image">
                        <img src={content.imageQuestions} alt="" />
                     </div>
                  </div>
                  <div className="offset-xl-2 col-xl-6">
                     <div className="questions">
                        <h2 className="questions__title">
                           Q&A about <br />
                           the procedure
                        </h2>
                        <Accordion className='questions__list' transition transitionTimeout={400} ref={listRef}>
                           {content.questions.map((question, index) => (
                              <AccordionItem
                                 className="questions__list-item"
                                 header={
                                    <h3 className="title">
                                       <span>{question.title}</span>
                                       <div className="icon">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none">
                                             <path d="M1 1.24512L8 7.24512L15 1.24512" stroke="#929191" strokeWidth="1.8" />
                                          </svg>
                                       </div>
                                    </h3>
                                 }
                                 key={index}>
                                 {question.text}
                              </AccordionItem>
                           ))}
                        </Accordion>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section >
   );
}

export default ServicePageSpecialist;